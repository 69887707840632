<template>
  <div class="about-page">
    <BaseHeader></BaseHeader>
    <BaseSecondaryPagesHeader
            title="Войти в кабинет"
            isSecondLevel="true"
    ></BaseSecondaryPagesHeader>
    <AuthPage></AuthPage>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import BaseHeader from '@/components/header/BaseHeader.vue';
import Footer from '@/components/general/Footer.vue';
import BaseSecondaryPagesHeader from '@/components/header/BaseSecondaryPagesHeader.vue';
import AuthPage from '@/components/secondary-pages/Auth.vue';

export default {
  name: 'Auth',
  components: {
    BaseSecondaryPagesHeader, AuthPage, BaseHeader, Footer,
  },
};
</script>
