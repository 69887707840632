<template>
    <footer>
        <div class="footer__left">
            © Elena.Shop. 2023 <br>
            <router-link to="/agreement">Пользовательское соглашение</router-link>
            <div class="footer__contacts" @click="showContacts()">Контакты</div>
        </div>
        <div class="footer__right">
            <div class="footer__info">
            ИП ГОСТЕВА ЕЛЕНА ВЯЧЕСЛАВОВНА, ИНН 673110487432, ОГРН 318673300023780, <br>
            РФ, 214018, Смоленская область, г. Смоленск, пер. Киевский, д.17, корп.1, кв.76, <br>
            e-mail - <a href="mailto:info@elena.shop">info@elena.shop</a>
            </div>

            <div class="footer__social">
                <a href="https://www.instagram.com/elena.siaglo/"
                   class="footer__social-icon inst"
                ></a>
                <a href="https://t.me/elenasiaglo"
                   class="footer__social-icon vk"
                ></a>
            </div>
        </div>
    </footer>
</template>

<script>

export default {
  name: 'Footer',
  components: {},
  methods: {
    showContacts() {
      this.$store.commit('setCurrentTemplate', 'contacts-footer');
      this.$store.commit('showDialog');
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss">
@import "../../assets/scss/common/variables";

footer {
    min-height: 74px;
    background: #1d1d1d;
    color: #9B9B9B;
    font-size: 12px;
    display: flex;
    align-items: center;
    line-height: 22px;

    a {
        color: #9B9B9B;
    }

    div {
        width: 100%;
    }

    .footer {
        &__left {
            width: 450px;
            padding: 15px 0 15px 30px;
        }

        &__right {
            font-size: 10px;
            line-height: 17px;

            @media only screen and (max-width: 767px) {
                width: 80px;
                padding-right: 23px;
            }
        }

        &__info {
            @media only screen and (max-width: 767px) {
                display: none;
            }
        }

        &__contacts {
            text-decoration: underline;

            @media only screen and (min-width: 767px) {
                display: none;
            }
        }

        &__social {
            display: flex;

            @media only screen and (min-width: 767px) {
                display: none;
            }
        }

        &__social-icon {
            width: 40px;
            height: 40px;
            background: url('/images/telegram-icon.svg') 50% 50% no-repeat;
            display: block;

            &.inst {
                background: url('/images/insta-icon.svg') 50% 50% no-repeat;
            }
        }
    }
}

</style>
